import axios from 'axios';
import fileDownload from 'js-file-download';

const handleDownload = (url, filename, pagination) => {
  axios.get('/api/Player/' + pagination).then(() => {
    axios
      .get(url, {
        responseType: 'blob'
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  });
};

export default handleDownload;
