import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { AppForm, AppFormField } from '../forms';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import resetAdminValidation from '../../validations/resetAdmin';
import { useAdminResetStore } from '../../store/adminResetPassword';

const ChangePassword = () => {
  const history = useHistory();

  const {
    reset,
    isShowedInvalidParameters,
    isShowedButtonLoading,
    changeInvalidParameter
  } = useAdminResetStore();

  // useEffect(() => {
  //   !isShowedButtonLoading
  //     ? history.replace("/reset-password")
  //     : history.replace("/login");
  // }, [history]);

  const clearInvalidParameter = useCallback(() => {
    changeInvalidParameter(false);
  }, [changeInvalidParameter]);

  return (
    <>
      <div className='d-flex align-items-center auth px-0'>
        <div className='row w-100 mx-0'>
          <div className='col-xs-6 mx-auto'>
            <div className='auth-form-light text-left py-5 px-4 px-sm-5'>
              <div className='heading-reset'>
                <h1>Փոփոխել գաղտնաբառը</h1>
              </div>

              <AppForm
                initialValues={{
                  oldPassword: '',
                  newPassword: '',
                  repeatNewPassword: ''
                }}
                onSubmit={reset}
                validationSchema={resetAdminValidation}
              >
                {() => {
                  return (
                    <>
                      {' '}
                      <AppFormField
                        type='text'
                        name='oldPassword'
                        placeholder='Հին գաղտնաբառ'
                        size='lg'
                        className='h-auto'
                        onChange={clearInvalidParameter}
                        style={{ width: '415px' }}
                      />
                      <AppFormField
                        type='password'
                        placeholder='Նոր գաղտնաբառ'
                        size='lg'
                        className='h-auto'
                        name='newPassword'
                        onChange={clearInvalidParameter}
                      />
                      <AppFormField
                        type='password'
                        placeholder='Հաստատել գաղտնաբառը'
                        size='lg'
                        className='h-auto'
                        name='repeatNewPassword'
                        onChange={clearInvalidParameter}
                      />
                      <div
                        className='mt-3'
                        style={{ width: '185px', margin: 'auto' }}
                      >
                        <button
                          type='submit'
                          className={cx(
                            'btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn'
                          )}
                        >
                          {isShowedButtonLoading ? (
                            <span
                              class='spinner-border spinner-border-sm mr-2'
                              role='status'
                              aria-hidden='true'
                            ></span>
                          ) : (
                            <div style={{ display: 'flex' }}>
                              <i className='fas fa-check mr-2'></i> Փոփոխել
                            </div>
                          )}
                        </button>
                      </div>
                      {isShowedInvalidParameters && (
                        <div className='text-center error-massage mt-2'>
                          Սխալ տվյալներ
                        </div>
                      )}
                    </>
                  );
                }}
              </AppForm>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChangePassword;
