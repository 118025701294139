import React, { memo } from 'react';
import { ErrorMessage, FastField } from 'formik';

import { default as CustomErrorMessage } from '../forms/ErrorMessage';

const CustomTextArea = ({
  name,
  htmlFor,
  title,
  className,
  id,
  rows,
  maxLength
}) => {
  return (
    <>
      <FastField name={name}>
        {({ field, _, meta }) => {
          return (
            <>
              <label htmlFor={htmlFor}>{title}</label>
              <textarea
                {...field}
                className={className}
                id={id}
                rows={rows}
                name={name}
                maxLength={maxLength}
              ></textarea>

              <ErrorMessage name={name} component={CustomErrorMessage} />
            </>
          );
        }}
      </FastField>
    </>
  );
};

export default memo(CustomTextArea);
