import React, { memo } from "react";
import { ErrorMessage, FastField } from "formik";

import CustomInput from "../shared/CustomInput";
import { default as CustomErrorMessage } from "./ErrorMessage";

const AppFormField = ({ name, wrapperClassName, ...otherProps }) => {
  return (
    <>
      <FastField name={name}>
        {({ field, _, meta }) => {
          return (
            <>
              <div>
                <CustomInput
                  {...field}
                  {...otherProps}
                  onChange={(...args) => {
                    field.onChange(...args);
                    if (otherProps.onChange) otherProps.onChange(...args);
                  }}
                />

                <ErrorMessage name={name} component={CustomErrorMessage} />
              </div>
            </>
          );
        }}
      </FastField>
    </>
  );
};

export default memo(AppFormField);
