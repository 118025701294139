import * as Yup from "yup";

const adminLoginValidation = Yup.object().shape({
  name: Yup.string()
    .required("Պարտադիր դաշտ")
    .min(6, "Մուտքանունը պետք է նվազագույնը 6 նիշ, առավելագույնը 20 նիշ")
    .max(20, "Մուտքանունը պետք է նվազագույնը 6 նիշ, առավելագույնը 20 նիշ")
    .label("Մուտքանունը")
    .matches(
      /(?:\d+[a-z]|[a-z]+\d)[a-z\d]*/,
      // /^([a-zA-Z0-9]+)$/,
      "Մուտքանունը պետք է պարունակի լատինատառեր ու թվեր, նվազագույնը 6 նիշ, առավելագույնը 20 նիշ"
    ),
  password: Yup.string()
    .required("Պարտադիր դաշտ")
    .min(
      6,
      "Գաղտնաբառը պետք է պարունակի լատինատառեր ու թվեր, նվազագույնը 6 նիշ, առավելագույնը 20 նիշ"
    )
    .max(
      20,
      "Գաղտնաբառը պետք է պարունակի լատինատառեր ու թվեր, նվազագույնը 6 նիշ, առավելագույնը 20 նիշ"
    )
    .label("Password")
    .matches(
      /(?:\d+[a-z]|[a-z]+\d)[a-z\d]*/,
      // /^([a-zA-Z0-9]+)$/,
      "Գաղտնաբառը պետք է պարունակի լատինատառեր ու թվեր, նվազագույնը 6 նիշ, առավելագույնը 20 նիշ"
    ),
});

export default adminLoginValidation;
