import React, { useEffect, useMemo } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import amFlag from '../../assets/images/flags/flag-arm.svg';
import enFlag from '../../assets/images/flags/flag-en.svg';
import ruFlag from '../../assets/images/flags/flag-ru.svg';
import { useQuestionStore } from '../../store/questions';
import questionAddValidation from '../../validations/questionAdd';
import { AppForm, AppFormField, ErrorMessage } from '../forms';
import { CustomButton } from '../shared';
import CustomDropDown from '../shared/CustomDropDown';
import CustomTextArea from '../shared/CustomTextArea';

const Questions = ({ isEdit = false }) => {
  const {
    isShowedButtonLoading,
    addQuestion,
    questionIndividual,
    questionIndividualGet,
    removeEditedQuestion
  } = useQuestionStore();

  const flagConfig = useMemo(
    () => ({
      am: {
        flag: amFlag,
        title: 'Armenia'
      },
      en: {
        flag: enFlag,
        title: 'English'
      },
      ru: {
        flag: ruFlag,
        title: 'Russian'
      }
    }),
    []
  );
  const params = useParams();

  useEffect(() => {
    questionIndividualGet(params.questionId);

    return () => {
      removeEditedQuestion();
    };
  }, []);

  if (isEdit && !questionIndividual) return null;

  return (
    <>
      <div>
        <div className='row questions_wrapper'>
          <div className='col-md-12 grid-margin stretch-card'>
            <div className='card border-0'>
              <div className='card-body card_tab'>
                <AppForm
                  initialValues={
                    questionIndividual
                      ? {
                          level: questionIndividual.find((q) => q.level)?.level,
                          section: questionIndividual.find((q) => q.section)
                            ?.section,
                          rightAnswer: questionIndividual.find(
                            (q) => q.rightAnswer
                          )?.rightAnswer,
                          questions: [
                            ...questionIndividual,
                            ...['am', 'en', 'ru']
                              .filter(
                                (lang) =>
                                  !questionIndividual.find(
                                    (q) => q.language.toLowerCase() === lang
                                  )
                              )
                              ?.map((l) => ({
                                question: '',
                                language: l,
                                level: '',
                                section: '',
                                rightAnswer: 0,
                                firstAnswer: '',
                                secondAnswer: '',
                                thirdAnswer: '',
                                fourthAnswer: ''
                              }))
                          ]
                        }
                      : {
                          level: '',
                          section: '',
                          rightAnswer: 0,
                          questions: [
                            {
                              question: '',
                              language: 'am',
                              level: '',
                              section: '',
                              rightAnswer: 0,
                              firstAnswer: '',
                              secondAnswer: '',
                              thirdAnswer: '',
                              fourthAnswer: ''
                            },
                            {
                              question: '',
                              language: 'en',
                              level: '',
                              section: '',
                              rightAnswer: 0,
                              firstAnswer: '',
                              secondAnswer: '',
                              thirdAnswer: '',
                              fourthAnswer: ''
                            },
                            {
                              question: '',
                              language: 'ru',
                              level: '',
                              section: '',
                              rightAnswer: 0,
                              firstAnswer: '',
                              secondAnswer: '',
                              thirdAnswer: '',
                              fourthAnswer: ''
                            }
                          ]
                        }
                  }
                  onSubmit={addQuestion}
                  validationSchema={questionAddValidation}
                >
                  {(form) => {
                    const isNotFilledAnyQuestion =
                      form.isValid &&
                      form.submitCount > 0 &&
                      form.values.questions.every((q) => !q.question);

                    return (
                      <>
                        <Tabs>
                          {form.values.questions.map((question, index) => (
                            <Tab
                              key={question.language}
                              eventKey={question.language}
                              title={
                                <img
                                  src={
                                    flagConfig[question.language.toLowerCase()]
                                      ?.flag
                                  }
                                  alt={
                                    flagConfig[question.language.toLowerCase()]
                                      ?.title
                                  }
                                  className='w-100'
                                />
                              }
                              className='test-tab'
                            >
                              <div className='card card__tab'>
                                <div className='card-body'>
                                  <div className='forms-sample'>
                                    <div className='my-3'>
                                      <CustomDropDown
                                        options={[
                                          'Համայնքային բյուջե',
                                          'Համայնքային տարեկան աշխատանքային պլան',
                                          'Համայնքային հնգամյա զարգացման ծրագիր'
                                        ]}
                                        name='section'
                                        htmlFor='section'
                                        // wrapperClass='mb-3 question__select'
                                        title='Բաժին'
                                        // className="form-control"
                                        id='section'
                                      />
                                    </div>
                                    <div className='my-3'>
                                      <CustomDropDown
                                        options={[
                                          'Ավագանու թեկնածու',
                                          'Համայնքի ակտիվ բնակիչ'
                                        ]}
                                        name='level'
                                        htmlFor='level'
                                        // wrapperClass='mb-3 question__select'
                                        title='Մակարդակ'
                                        // className="form-control"
                                        id='level'
                                      />
                                    </div>

                                    <CustomTextArea
                                      name={`questions[${index}].question`}
                                      title='Հարց'
                                      className='form-control'
                                      rows='4'
                                    />
                                    <div className='row flex-wrap'>
                                      <div className='col-12 col-md-6 my-3'>
                                        <label>Տարբերակ 1</label>
                                        <CustomTextArea
                                          labelTitle='Տարբերակ 1'
                                          type='text'
                                          className='form-control questionHeight'
                                          name={`questions[${index}].firstAnswer`}
                                          style={{ height: '80px' }}
                                        />
                                      </div>
                                      <div className='col-12 col-md-6 my-3'>
                                        <label>Տարբերակ 2</label>
                                        <CustomTextArea
                                          labelTitle='Տարբերակ 2'
                                          type='text'
                                          className='form-control questionHeight'
                                          name={`questions[${index}].secondAnswer`}
                                        />
                                      </div>
                                      <div className='col-12 col-md-6 my-3'>
                                        <label>Տարբերակ 3</label>
                                        <CustomTextArea
                                          labelTitle='Տարբերակ 3'
                                          type='text'
                                          className='form-control questionHeight'
                                          name={`questions[${index}].thirdAnswer`}
                                        />
                                      </div>
                                      <div className='col-12 col-md-6 my-3'>
                                        <label>Տարբերակ 4</label>
                                        <CustomTextArea
                                          labelTitle='Տարբերակ 4'
                                          type='text'
                                          className='form-control questionHeight'
                                          name={`questions[${index}].fourthAnswer`}
                                        />
                                      </div>
                                    </div>
                                    <CustomDropDown
                                      options={['1', '2', '3', '4']}
                                      name='rightAnswer'
                                      htmlFor='correctAnswer'
                                      wrapperClass='mb-3 question__select'
                                      title='Ճիշտ պատասխան'
                                      // className="form-control"
                                      id='correctAnswer'
                                    />
                                  </div>
                                </div>
                              </div>
                            </Tab>
                          ))}
                        </Tabs>

                        <div className='mt-5 text-center add-qustion-button'>
                          <CustomButton
                            buttonTitle={isEdit ? 'Փոփոխել' : 'Ավելացնել'}
                            className='btn btn-dark'
                            buttonIcon={
                              isShowedButtonLoading
                                ? 'spinner-border'
                                : `${
                                    isEdit ? 'fas fa-check' : 'fas fa-plus'
                                  } mr-2`
                            }
                            type='submit'
                          />

                          {isNotFilledAnyQuestion ? (
                            <ErrorMessage>Լրացրեք բոլոր դաշտերը</ErrorMessage>
                          ) : null}
                        </div>
                      </>
                    );
                  }}
                </AppForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Questions;
