import cx from 'classnames';
import React, { useMemo, useState } from 'react';

const CustomTabs = ({ tabs = [], onChange }) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0]?.key || 0);

  const renderedTabTitles = useMemo(() => {
    return tabs.map((tab, index) => (
      <button className={cx("btn btn-lg mr-2 mb-2", {
        'btn-outline-dark': selectedTab !== (tab.key || index),
        'btn-dark': selectedTab === (tab.key || index),
      })} onClick={() => {
        setSelectedTab(tab.key || index);
        if (onChange) onChange(tab.key || index)
      }}>{tab.title}</button>
    ))
  }, [tabs, selectedTab, onChange]);

  const renderedTabContent = useMemo(() => tabs.find((t, index) => (t.key || index) === selectedTab)?.content, [selectedTab])

  return (
    <>
      <div className="d-flex flex-wrap">
        {renderedTabTitles}
      </div>

      {renderedTabContent}
    </>
  )
};

export default CustomTabs;