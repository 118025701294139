import apiService from "../api";

class AdminAuthController {
  async login(userData) {
    try {
      const userInfoResponse = await apiService.post("/Auth/Login", userData);

      return userInfoResponse.data.data;
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }
}

const adminAuthController = new AdminAuthController();

export default adminAuthController;
