import axios from 'axios';
import { ACCESS_TOKEN_LS } from '../../configs/storage';

const API_URL = {
  local: '/api'
}[['local'][0]];

class ApiService {
  instance = null;

  constructor() {
    this.instance = axios.create({
      baseURL: API_URL
    });

    const initialToken = localStorage.getItem(ACCESS_TOKEN_LS);

    if (initialToken) this.setAccessToken(initialToken);
  }

  setAccessToken(accessToken) {
    this.instance.defaults.headers.common['Authorization'] = accessToken;

    this.instance.interceptors.response.use((response) => {
      console.log(response);
      if (response.status === 403) {
        localStorage.removeItem(ACCESS_TOKEN_LS);
        window.location.reload();

        return;
      }

      return response;
    }, Promise.reject);
  }

  get(url) {
    return this.instance.get(url);
  }

  post(url, data) {
    return this.instance.post(url, data);
  }

  delete(url, queries) {
    return this.instance.delete(`${url}?${queries}`);
  }
  put(url, data) {
    return this.instance.put(url, data);
  }
}

const apiService = new ApiService();

export default apiService;
