import apiService from '../api';

class CertificationController {
  async certificationGet() {
    try {
      const certificationGet = await apiService.get(
        '/Certificate/GetCertificateText'
      );

      return certificationGet.data.data;
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }

  async certificationEdit(englishText, armenianText,russianText) {
    try {
      const certificationGet = await apiService.post(
        '/Certificate/ChangeCertificateText',
        {
          armenianText,
          englishText,
          russianText
        }
      );

      if (!certificationGet.data.data) throw new Error('');
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }
}

const certificationController = new CertificationController();

export default certificationController;
