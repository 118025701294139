import apiService from "../../api";

class QuestionInfoGetController {
  async questionIndividualGet(questionId) {
    try {
      const questionInfoResponse = await apiService.get(
        `/Questions/GetQuestionInfo?questionId=${questionId}`
      );

      return questionInfoResponse.data.data;
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }
}

const questionInfoGetController = new QuestionInfoGetController();

export default questionInfoGetController;
