import React, { useCallback, useEffect, useState } from 'react';
import { CustomButton } from '../shared';
import classes from './Certification.module.scss';
import { Card } from '../Card';
import { Download, Logo } from '../../assets/images/svg';
import { cx } from 'classnames';
import { useHistory } from 'react-router-dom';

const Certificate = () => {
  const userFullname = 'Full Name';

  const history = useHistory();
  // const [enText, setEnText] = useState('');
  // const [amText, setAmText] = useState('');

  return (
    <div>
      <Card classStyle={window.innerWidth > 991 && classes.card}>
        {window.innerWidth <= 991 && (
          <>
            <Logo />
            <div className={classes.certificateTitle}>Հավաստագիր</div>
          </>
        )}
        <div className={classes.CertificateWrapper} id='capture'>
          <div className={classes.certificateTextWrapper}>
            <p className={userFullname ? classes.smallTitle : classes.title}>
              Հավաստագիր
            </p>
            {/* <p className={classes.titleEn}>CERTIFICATE OF ACHIEVEMENT</p> */}
            <p className={classes.presented}>Տրվում է</p>
            <p className={userFullname ? classes.smallName : classes.name}>
              Անուն Ազգանուն
              {/* Anahit Danielyan */}
            </p>
            <div className={classes.aboutText}>
              <p className={classes.about}>
                {/* {certificateGet?.armenianText} */}
                &#171;Տիմխաղա&#187; առցանց խաղը ստեղծվել է ՄԱԶԾ &#171;Կանայք և
                երիտասարդները նորարար տեղական զարգացման &#171;Տիմխաղա&#187;
                առցանց խաղի &#171;"ԲԱԺԻՆ"&#187; բաժնի "ՄԱԿԱՐԴԱԿ" մակարդակը
                հաջողությամբ անցնելու համար։
              </p>
              {/* <p className={classes.aboutEn}>{certificateGet?.englishText}</p> */}
            </div>
            <div className={classes.signatureWrapper}>
              <p className={classes.date}>{Date.now()}</p>
              <p className={classes.place}>Լոռու մարզ, Ստեփանավան համայնք</p>
              <div className={classes.signature}></div>

              <p className={classes.footerText}>
                &#171;Տիմխաղա&#187; առցանց խաղը ստեղծվել է ՄԱԶԾ <br />
                &#171;Կանայք և երիտասարդները նորարար տեղական զարգացման
                գործընթացներում&#187; ծրագրի շրջանակներում, որը ֆինանսավորում է
                Շվեյցարիայի զարգացման և Համագործակցության գործակալության կողմից
                և իրականցվում է ՀՀ տարածքային կառավարմա և ենթակառուցվածքների
                նախարարության հետ համագործակցությամբ։
                <br />
                &#171;Տիմխաղա&#187; առցանց խաղը զարգացվել է &#171;ՀԿ
                Կենտրոն&#187; ՔՀԶ ՀԿ-ի կողմից։
              </p>
            </div>
            <div
              className={
                userFullname
                  ? classes.smallCertificationMedal2
                  : classes.certificationMedal2
              }
            ></div>
          </div>
        </div>
        <div
          className={
            ('d-flex',
            'mt-3',
            window.innerWidth <= 991
              ? 'justify-content-center'
              : 'justify-content-end',
            'buttonWrapper')
          }
        ></div>
      </Card>
    </div>
  );
};

export default Certificate;
