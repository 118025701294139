import apiService from "../../api";

class QuestionGetController {
  async questionGet(pagination) {
    try {
      const questionResponse = await apiService.post(
        "/Questions/GetQuestions",
        {
          pagination,
        }
      );

      return questionResponse.data.data;
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }
}

const questionGetController = new QuestionGetController();

export default questionGetController;
