import create from 'zustand';
import { playerController } from '../services/controllers';

export const usePlayersStore = create((set, get) => ({
  isShowedInvalidParameters: false,
  isShowedButtonLoading: false,
  isShowedWrongAnswersLoading: false,

  playerWrongAnswers: [],

  players: [],
  excel: '',

  playersGet: async (page, pageSize) => {
    try {
      set({ isShowedButtonLoading: true });

      const players = await playerController.playersGet({
        page,
        pageSize
      });

      set({ players });
    } catch (error) {
      console.log(error);
    }
  },

  playersExcelGet: async (page, pageSize) => {
    try {
      const excel = await playerController.playersExcelGet({
        page,
        pageSize
      });
      set({ excel });
    } catch (error) {
      console.log(error);
    }
  },

  deletedPlayer: null,
  addDeletedPlayer: (deletedPlayer) => set({ deletedPlayer }),

  deletePlayer: async (onPageSizeChange) => {
    const currentPage = get().players.result.currentPage;
    const results = get().players?.result?.results;

    try {
      const deletedPlayer = get().deletedPlayer;

      if (!deletedPlayer) return;

      await playerController.playerDelete(deletedPlayer);

      set({ deletedPlayer: null });
      get().playersGet(
        results?.length === 1 && currentPage > 1
          ? currentPage - 1
          : currentPage,
        10
      );

      if (onPageSizeChange)
        onPageSizeChange(
          results?.length === 1 && currentPage > 1
            ? currentPage - 1
            : currentPage
        );
    } catch (error) {
      console.log(error);
    }
  },

  playerIndividual: [],
  getPlayerWrongAnswers: async (playerId, level) => {
    try {
      set({ isShowedWrongAnswersLoading: true });

      const playerWrongAnswers = await playerController.getPlayerWrongAnswers(
        playerId,
        level
      );

      set({ isShowedWrongAnswersLoading: false });
      set({ playerWrongAnswers });
    } catch (error) {
      set({ isShowedWrongAnswersLoading: false });

      console.log(error);
    }
  },

  set
}));
