import apiService from "../../api";

class QuestionIndividualGetController {
  async questionIndividualGet(questionId) {
    try {
      const questionResponse = await apiService.get(
        `/Questions/GetQuestion/?questionId=${questionId}`
      );

      return questionResponse.data.data;
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }
}

const questionIndividualGetController = new QuestionIndividualGetController();

export default questionIndividualGetController;
