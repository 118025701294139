import apiService from '../../api';

class CommunityGetController {
  async communityGet(page, pageSize) {
    try {
      const communityResponse = await apiService.get(
        `/Community?Page=${page}&PageSize=${pageSize}`
      );

      return communityResponse.data;
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }

  async statesGet() {
    try {
      const statesResponse = await apiService.get(
        `/State/GetStateList?Pagination.Page=1&Pagination.PageSize=20&Language=Am`
      );

      return statesResponse.data.data.results;
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }
}

const communityGetController = new CommunityGetController();

export default communityGetController;
