import cx from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../assets/images/svg/logoblack.svg';
import { AppForm, AppFormField } from '../forms';

import { useAdminAuthStore } from '../../store/adminLogin';
import adminLoginValidation from '../../validations/admin';

const Login = () => {
  const history = useHistory();

  const {
    user,
    login,
    isShowedInvalidParameters,
    isShowedButtonLoading,
    changeInvalidParameter
  } = useAdminAuthStore((state) => state);

  useEffect(() => {
    user ? history.replace('/') : history.replace('/login');
  }, [user, history]);

  const clearInvalidParameter = useCallback(() => {
    changeInvalidParameter(false);
  }, [changeInvalidParameter]);

  return (
    <div>
      <div className='d-flex align-items-center auth px-0'>
        <div className='row w-100 mx-0'>
          <div className='col-lg-4 mx-auto'>
            <div className='auth-form-light text-left py-5 px-4 px-sm-5'>
              <div className='brand-logo'>
                <img src={logo} className='mr-2' alt='logo' />
              </div>

              <AppForm
                initialValues={{
                  name: '',
                  password: ''
                }}
                onSubmit={login}
                validationSchema={adminLoginValidation}
              >
                {() => {
                  return (
                    <div className='card-body'>
                      <div className='forms-sample'>
                        <div className='pt-3'>
                          <AppFormField
                            type='text'
                            name='name'
                            labelTitle='Մուտքանուն'
                            placeholder='Մուտքանուն'
                            size='lg'
                            className='h-auto'
                            onChange={clearInvalidParameter}
                          />

                          <AppFormField
                            type='password'
                            placeholder='Գաղտնաբառ'
                            labelTitle='Գաղտնաբառ'
                            size='lg'
                            className='h-auto'
                            name='password'
                            onChange={clearInvalidParameter}
                          />

                          <div className='mt-3'>
                            <button
                              type='submit'
                              className={cx(
                                'btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn'
                              )}
                            >
                              {isShowedButtonLoading ? (
                                <span
                                  class='spinner-border spinner-border-sm mr-2'
                                  role='status'
                                  aria-hidden='true'
                                ></span>
                              ) : (
                                'Մուտք'
                              )}
                            </button>
                          </div>

                          {isShowedInvalidParameters && (
                            <div className='text-center error-massage mt-2'>
                              Սխալ տվյալներ
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }}
              </AppForm>
            </div>
          </div>
        </div>
      </div>
      {/* {isShowedButtonLoading && (
        <div className="loader">
          <i className="fa fa-circle-o-notch fa-spin spinner-load"></i>
        </div>
      )} */}
    </div>
  );
};

export default Login;
