import React from 'react';
import { Form } from 'react-bootstrap';

const CustomInput = ({
  wrapperClassName,
  htmlFor,
  labelTitle,
  description,
  id,
  ...otherProps
}) => {
  return (
    <div className={wrapperClassName}>
      <Form.Group>
        <label htmlFor={id}>{labelTitle}</label>
        <Form.Control {...otherProps} id={id} />
        {description}
      </Form.Group>
    </div>
  );
};

export default CustomInput;
