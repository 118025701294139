import cogoToast from 'cogo-toast';
import create from 'zustand';
import {
  questionAddController,
  questionDeleteController,
  questionGetController,
  questionIndividualGetController,
  questionInfoGetController
} from '../services/controllers';
const toastOptions = {
  hideAfter: 5,
  position: 'top-right',
  heading: ''
};
export const useQuestionStore = create((set, get) => ({
  isShowedInvalidParameters: false,
  isShowedButtonLoading: false,

  selectedQuestionId: null,
  setSelectedQuestionId: (selectedQuestionId) => set({ selectedQuestionId }),

  changeInvalidParameter: () => set({ isShowedInvalidParameters: false }),
  removeEditedQuestion: () => set({ questionIndividual: null }),

  questions: [],
  questionsGet: async (page, pageSize) => {
    try {
      const questions = await questionGetController.questionGet({
        page,
        pageSize
      });

      set({ questions });
    } catch (error) {
      console.log(error);
    }
  },

  deletedQuestion: null,
  addDeletedQuestion: (deletedQuestion) => set({ deletedQuestion }),

  deleteQuestion: async (onPageSizeChange) => {
    const currentPage = get().questions?.currentPage;
    const results = get().questions?.results;

    try {
      const deletedQuestion = get().deletedQuestion;

      if (!deletedQuestion) return;

      await questionDeleteController.questionDelete(deletedQuestion);

      set({ deletedQuestion: null });
      get().questionsGet(
        results?.length === 1 && currentPage > 1
          ? currentPage - 1
          : currentPage,
        10
      );
      if (onPageSizeChange)
        onPageSizeChange(
          results?.length === 1 && currentPage > 1
            ? currentPage - 1
            : currentPage
        );

      cogoToast.info('Դուք հաջողությամբ ջնջեցիք հարցը', toastOptions);
    } catch (error) {
      console.log(error);
      cogoToast.info(
        'Դուք չեք կարող ջնջել հարցը , քանի որ կա ակտիվ խաղ, խնդրում ենք փորձել քիչ անց',
        toastOptions
      );
    }
  },
  addQuestion: async (values) => {
    try {
      const validQuestions = values.questions.filter((q) => q.question.trim());

      if (!validQuestions.length) return;

      const editedQuestion = get().questionIndividual;

      const transformedQuestions = validQuestions?.map((q) => ({
        _id: q._id,
        parentQuestionId: q.parentQuestionId,
        section:
          values.section === '1'
            ? 'CommunityBudget'
            : values.section === '2'
            ? 'AnnualWorkPlanOfCommunity'
            : 'CommunityFiveYearPlan',
        question: q.question,
        language: q.language.charAt(0).toUpperCase() + q.language.slice(1),
        level:
          values.level === '1'
            ? 'CandidateForTheCouncilOfElders'
            : 'ActiveResidentOfTheCommunity',
        answers: [
          {
            isTrue: +values.rightAnswer === 1,
            answer: q.firstAnswer
          },
          {
            isTrue: +values.rightAnswer === 2,
            answer: q.secondAnswer
          },
          {
            isTrue: +values.rightAnswer === 3,
            answer: q.thirdAnswer
          },
          {
            isTrue: +values.rightAnswer === 4,
            answer: q.fourthAnswer
          }
        ]
      }));
      set({ isShowedButtonLoading: true });
      if (editedQuestion) {
        await questionAddController.questionEdit(transformedQuestions);
        cogoToast.info('Դուք հաջողությամբ փոխեցիք հարցը', toastOptions);
      } else {
        await questionAddController.questionAdd(transformedQuestions);
        cogoToast.info('Դուք հաջողությամբ ավելացրեցիք հարցը', toastOptions);
      }

      set({ isShowedButtonLoading: false });
      setTimeout(() => {
        window.location.replace('/questions');
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  },

  questionIndividual: null,
  questionIndividualGet: async (questionId) => {
    try {
      const questions =
        await questionIndividualGetController.questionIndividualGet(questionId);

      set({
        questionIndividual: questions.map((q) => ({
          parentQuestionId: q.parentQuestionId,
          _id: q._id,
          question: q.question,
          section:
            q.section === 'CommunityBudget'
              ? '1'
              : 'AnnualWorkPlanOfCommunity'
              ? '2'
              : '3',
          language: q.language,
          level: q.level === 'CandidateForTheCouncilOfElders' ? '1' : '2',
          firstAnswer: q.answers[0].answer,
          secondAnswer: q.answers[1].answer,
          thirdAnswer: q.answers[2].answer,
          fourthAnswer: q.answers[3].answer,
          rightAnswer: q.answers.findIndex((a) => a.isTrue) + 1
        }))
      });
    } catch (error) {
      console.log(error);
    }
  },

  questionInfo: null,
  questionInfoIndividual: async (questionInfoid) => {
    try {
      if (!questionInfoid) return;

      const questionStatistics =
        await questionInfoGetController.questionIndividualGet(questionInfoid);

      set({ questionInfo: null });
      set({ questionStatistics });
    } catch (error) {
      console.log(error);
    }
  },

  set
}));
