import apiService from '../../api';

class CommunityUpdateController {
  async communityUpdate(communityData) {
    try {
      const communityResponse = await apiService.put(
        '/Community',
        communityData
      );

      return communityResponse.data;
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }
}

const communityUpdateController = new CommunityUpdateController();

export default communityUpdateController;
