import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { routeUrls } from '../../configs/routes';
import { useCommunityStore } from '../../store/community';
import { CustomButton } from '../shared';
import CustomPopup from '../shared/CustomPopup';
import CustomTable from '../shared/CustomTable';
import CustomPagination from '../shared/Pagination';

const CommunityList = () => {
  const [selected, setSelected] = useState(1);
  const [showInput, setShowInput] = useState();
  const history = useHistory();
  const {
    addDeletedCommunity,
    deletedCommunity,
    communitiesGet,
    communities,
    deleteCommunity,
    setSelectedCommunityId
  } = useCommunityStore();

  const onAddCommunityClick = useCallback(
    () => history.push(routeUrls.ADD_COMMUNITY),
    [history]
  );

  const pageCount = communities?.data?.pageCount;

  useEffect(() => {
    communitiesGet(selected, 10);
  }, [communitiesGet, selected]);

  return (
    <>
      <div className='community_wrapper'>
        <div className='text-center text-md-right'>
          <CustomButton
            handleButtonClick={onAddCommunityClick}
            buttonTitle='Ավելացնել համայնք'
            className='btn btn-dark'
            buttonIcon='fas fa-plus mr-2'
          />
        </div>
        <CustomTable
          tableHeaders={['Համայնք', 'Գործողություն']}
          tableColumns={communities?.data?.results}
          renderColumns={(column) => (
            <>
              <td style={{ width: '50%' }}>{column.name} </td>
            </>
          )}
          onDeleteClick={(community) => addDeletedCommunity(community._id)}
          onDetailsClick={(community) => {
            history.push(`/community/edit/${community._id}`);
          }}
          onEditClick={(community) => {
            setSelectedCommunityId(community._id);
          }}
        />
        {pageCount > 1 && (
          <CustomPagination
            count={pageCount}
            current={selected}
            onChange={(value) => setSelected(value)}
          />
        )}
        <CustomPopup
          title='Ջնջել՞'
          isShowed={Boolean(deletedCommunity)}
          onConfirm={() =>
            deleteCommunity((currentPage) => setSelected(currentPage))
          }
          onClose={() => addDeletedCommunity(null)}
        />
      </div>
    </>
  );
};

export default CommunityList;
