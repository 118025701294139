import create from "zustand";
import { StatusCodes } from "../configs/api";
import { ACCESS_TOKEN_LS } from "../configs/storage";
import apiService from "../services/api";
import { adminResetController } from "../services/controllers";
import { useAdminAuthStore } from "./adminLogin";

const changeAdminStore = useAdminAuthStore.setState;

export const useAdminResetStore = create((set, get) => ({
  isShowedInvalidParameters: false,
  isShowedButtonLoading: false,
  changeInvalidParameter: () => set({ isShowedInvalidParameters: false }),
  reset: async (values) => {
    try {
      set({ isShowedButtonLoading: true });

      const newPassword = await adminResetController.reset({
        userName: "Admin1",
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
        confirmPassword: values.repeatNewPassword,
      });

      set({ newPassword });
      changeAdminStore({ user: null });
      localStorage.removeItem(ACCESS_TOKEN_LS);
    } catch (error) {
      if (
        error.status === StatusCodes.USER_NOT_FOUND ||
        StatusCodes.THE_OLD_PASSWORD_IS_INCORRECT
      )
        set({ isShowedInvalidParameters: true });
    } finally {
      set({ isShowedButtonLoading: false });
    }
  },
  set,
}));
