import * as Yup from 'yup';

const requiredValidation = Yup.string()
  .required('Պարտադիր դաշտ')
  .min(1, 'Պատասխանը պետք է պարունակի առավելագույնը 150 նիշ')
  .max(150, 'Պատասխանը պետք է պարունակի առավելագույնը 150 նիշ');

const requiredQuestionsValidation = (exclude) =>
  Yup.string().when(exclude, {
    is: (value) => value?.length,
    then: Yup.string()
      .notOneOf(
        ['firstAnswer', 'secondAnswer', 'thirdAnswer', 'fourthAnswer']
          .filter((p) => p !== exclude)
          .map((p) => Yup.ref(p)),
        'Տարբերակները չպետք է կրկնվեն'
      )
      .max(150, 'Պատասխանը պետք է պարունակի առավելագույնը 150 նիշ'),
    otherwise: requiredValidation
  });

const requiredWhenQuestionIsExist = (validations = requiredValidation) =>
  Yup.string().when('question', {
    is: (value) => value && value.length > 0,
    otherwise: Yup.string(),
    then: validations
  });

const questionAddValidation = Yup.object().shape({
  level: requiredValidation,
  section: requiredValidation,
  rightAnswer: Yup.number().min(1, 'Պարտադիր դաշտ').required('Պարտադիր դաշտ'),
  questions: Yup.array().of(
    Yup.object({
      // rightAnswer: Yup.number().when("question", {
      //   is: (value) => value && value.length > 0,
      //   otherwise: Yup.number(),
      //   then: Yup.number().min(1, "Պարտադիր դաշտ").required("Պարտադիր դաշտ"),
      // }),
      question: Yup.string().max(
        300,
        'Հարցը պետք է պարունակի առավելագույնը 300 նիշ'
      ),
      firstAnswer: requiredWhenQuestionIsExist(
        requiredQuestionsValidation('firstAnswer')
      ),
      secondAnswer: requiredWhenQuestionIsExist(
        requiredQuestionsValidation('secondAnswer')
      ),
      thirdAnswer: requiredWhenQuestionIsExist(
        requiredQuestionsValidation('thirdAnswer')
      ),
      fourthAnswer: requiredWhenQuestionIsExist(
        requiredQuestionsValidation('fourthAnswer')
      )
    })
  )
});

export default questionAddValidation;
