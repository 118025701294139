import React, { Component, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Login from '../components/login';
import Questions from '../components/questions/Questions';
import ResetPassword from '../components/reset-password';
import Users from '../components/users/Users';
import WrongUsers from '../components/wrong-users/WrongUsers';
import Certificate from './certificate/Certificate';
import Community from './community/Community';
import CommunityList from './community/CommunityList';
import QuestionsList from './questions/QuestionsList';
import Spinner from './shared/Spinner';

class AppRoutes extends Component {
  render() {
    const windowLocation = window.location.pathname;

    return (
      <Suspense fallback={<Spinner />}>
        <div
          className={windowLocation === '/certificate' ? '' : 'page__wrapper'}
        >
          <Switch>
            <Route exact path='/questions' component={QuestionsList} />
            <Route exact path='/questions/add' component={Questions} />
            <Route
              exact
              path='/questions/edit/:questionId'
              render={(props) => <Questions {...props} isEdit />}
            />

            <Route exact path='/users' component={Users} />
            <Route
              exact
              path='/wrong-answers/:playerId'
              component={WrongUsers}
            />
            <Route exact path='/login' component={Login} />
            <Route exact path='/reset-password' component={ResetPassword} />
            <Route exact path='/certificate' component={Certificate} />

            <Route exact path='/community' component={CommunityList} />
            <Route
              exact
              path='/community/add'
              render={(props) => <Community {...props} isEdit={false} />}
            />

            <Route
              exact
              path='/community/edit/:communityId'
              render={(props) => <Community {...props} isEdit={true} />}
            />

            <Redirect to='/questions' />
          </Switch>
        </div>
      </Suspense>
    );
  }
}

export default AppRoutes;
