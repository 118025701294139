import certificationController from './certification';

//Auth
export { default as adminLoginController } from './adminAuth';
export { default as adminResetController } from './adminReset';
// players
export { default as playerController } from './players';

// questions
export { default as questionAddController } from './questions/questionsAdd';
export { default as questionGetController } from './questions/questionsGet';
export { default as questionDeleteController } from './questions/questionDelete';
export { default as questionIndividualGetController } from './questions/questionIndividualGet';
export { default as questionInfoGetController } from './questions/questionInfo';

export { default as certificationController } from './certification';

// community

export { default as communityGetController } from './community/communityGetAll';
export { default as communityDeleteController } from './community/communityDelete';
export { default as communityIndividualGetController } from './community/communityIndividualGet';
export { default as communityAddController } from './community/communityAdd';
export { default as communityUpdateController } from './community/communityUpdate';
