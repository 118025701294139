import cx from 'classnames';
import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { CloseIcon } from '../../assets/images/svg';
import { usePlayersStore } from '../../store/players';

const WrongAnswers = () => {
  const { playerWrongAnswers, isShowedWrongAnswersLoading } = usePlayersStore();

  if (!playerWrongAnswers?.length)
    return <div className='text-center mt-4'>Սխալ պատասխաններ չկան</div>;

  return (
    <div
      className={cx('mt-4', {
        'opacity-loading': isShowedWrongAnswersLoading
      })}
    >
      {playerWrongAnswers.map((wrongAnswer, idx) => (
        <React.Fragment key={idx}>
          <Accordion>
            <Card>
              <Card.Header>
                <Accordion.Toggle
                  as={(props) => <div className='cursor-pointer' {...props} />}
                  variant='link'
                  eventKey='0'
                >
                  <span className='d-block mb-2'>
                    Խաղ № {wrongAnswer.gameId}
                  </span>
                  <CloseIcon /> Սխալ պատասխաններ -{' '}
                  {wrongAnswer.questionAnswers.length}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey='0'>
                <Card.Body>
                  <table border='1' className='table table_modal'>
                    <thead>
                      <tr>
                        <th>Հարց</th>
                        <th>Պատասխան</th>
                      </tr>
                    </thead>
                    <tbody>
                      {wrongAnswer.questionAnswers.map((wrongQuestion, idx) => (
                        <tr key={idx}>
                          <td>{wrongQuestion.question}</td>
                          <td>{wrongQuestion.answer}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </React.Fragment>
      ))}
    </div>
  );
};

export default WrongAnswers;
