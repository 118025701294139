import React, { memo } from "react";
import cx from "classnames";
import { Link } from "react-router-dom";
const CustomButtom = ({
  handleButtonClick,
  buttonTitle,
  className,
  buttonIcon,
  buttonProps = {},
  type = "submit",
}) => {
  return (
    // <Link to="/questions">
    <button
      type={type}
      onClick={handleButtonClick}
      className={cx("btn btn-dark", className)}
      {...buttonProps}
    >
      <i className={cx(buttonIcon, "mr-2")}></i>
      {buttonTitle}
    </button>
    // </Link>
  );
};

export default memo(CustomButtom);
