import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useCommunityStore } from '../../store/community';
import CustomDropDown from '../shared/CustomDropDown';

const StatesSelect = () => {
  const { setFieldValue } = useFormikContext();

  const { states, statesGet } = useCommunityStore();

  useEffect(() => {
    statesGet();
  }, []);

  return (
    <CustomDropDown
      options={states.map((state) => ({
        label: state.translations.find((s) => s.language === 'Am')?.name,
        value: state._id
      }))}
      name='stateId'
      htmlFor='stateId'
      wrapperClass='my-3'
      title='Մարզ'
      className='form-control'
      id='stateId'
      handleChange={(option) => setFieldValue('stateId', option.target.value)}
    />
  );
};

export default StatesSelect;
