import apiService from "../../api";

class QuestionAddController {
  async questionAdd(questionData) {
    try {
      const questionResponse = await apiService.post(
        "/Questions/CreateQuestions",
        questionData
      );

      return questionResponse.data;
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }

  async questionEdit(questionData) {
    try {
      const questionResponse = await apiService.post(
        "/Questions/UpdateQuestion",
        questionData
      );

      return questionResponse.data;
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }
}

const questionAddController = new QuestionAddController();

export default questionAddController;
