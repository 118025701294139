import apiService from "../../api";

class QuestionDeleteController {
  async questionDelete(questionID) {
    try {
      await apiService.get(
        `/Questions/DeleteQuestion?questionId=${questionID}`
      );

      return Promise.resolve();
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }
}

const questionDeleteController = new QuestionDeleteController();

export default questionDeleteController;
