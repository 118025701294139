import React, { memo } from 'react';
import {
  EditIcon,
  EyeIcon,
  ResearchIcon,
  TrashIcon
} from '../../assets/images/svg';

const CustomTable = ({
  isShowedEditIcon,
  tableHeaders = [],
  tableColumns,
  isShownViewIcon,
  onEditClick = () => {},
  onDeleteClick = () => {},
  onDetailsClick = () => {},
  renderColumns = () => {}
}) => {
  return (
    <div className='table-responsive'>
      <table className='table table-striped mt-5 text-center'>
        <thead className='bg-dark text-white '>
          <tr>
            {tableHeaders.map((head, idx) => (
              <th key={idx}> {head} </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableColumns?.map((columns, idx) => (
            <tr key={idx}>
              {renderColumns(columns)}

              <td>
                <button
                  className='btn-sm bg-transparent border-0'
                  onClick={() => onEditClick(columns)}
                >
                  {isShownViewIcon && <EyeIcon />}
                </button>
                {isShowedEditIcon && (
                  <button
                    className='border-0 bg-transparent mx-4'
                    onClick={() => onDetailsClick(columns)}
                  >
                    <EditIcon />
                  </button>
                )}
                <button
                  className='border-0 bg-transparent'
                  onClick={() => onDeleteClick(columns)}
                >
                  <TrashIcon />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default memo(CustomTable);
