import React, { useCallback, useEffect, useReducer } from "react";
import { withRouter } from "react-router-dom";
import "./App.scss";
import AppRoutes from "./components/AppRoutes";
import Navbar from "./components/shared/Navbar";
import Sidebar from "./components/shared/Sidebar";
import SettingsPanel from "./components/shared/SettingsPanel";
import Footer from "./components/shared/Footer";
import Login from "./components/login/Login";
import { useAdminAuthStore } from "./store";

const App = ({ location }) => {
  const [state, setState] = useReducer(
    (state, changeState) => ({ ...state, ...changeState }),
    {}
  );
  const { user } = useAdminAuthStore();

  let navbarComponent = !state.isFullPageLayout ? <Navbar /> : "";
  let sidebarComponent = !state.isFullPageLayout ? <Sidebar /> : "";
  let SettingsPanelComponent = !state.isFullPageLayout ? <SettingsPanel /> : "";
  let footerComponent = !state.isFullPageLayout ? <Footer /> : "";

  const onRouteChanged = useCallback(() => {
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ["/login"];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (location.pathname === fullPageLayoutRoutes[i]) {
        setState({
          isFullPageLayout: true,
        });
        document
          .querySelector(".page-body-wrapper")
          .classList.add("full-page-wrapper");
        break;
      } else {
        setState({
          isFullPageLayout: false,
        });
        document
          .querySelector(".page-body-wrapper")
          .classList.remove("full-page-wrapper");
      }
    }
  }, [location]);

  useEffect(() => {
    onRouteChanged();
  }, [onRouteChanged, location.pathname, user]);

  return (
    <div className="container-scroller">
      {navbarComponent}
      <div className="container-fluid page-body-wrapper">
        {sidebarComponent}
        <div className="main-panel">
          <div className="content-wrapper">
            {user ? <AppRoutes /> : <Login />}
            {SettingsPanelComponent}
          </div>
          {footerComponent}
        </div>
      </div>
    </div>
  );
};

export default withRouter(App);
