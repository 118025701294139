import create from "zustand";
import { StatusCodes } from "../configs/api";
import { ACCESS_TOKEN_LS } from "../configs/storage";
import apiService from "../services/api";
import { adminLoginController } from "../services/controllers";

export const useAdminAuthStore = create((set, get) => ({
  user: Boolean(localStorage.getItem(ACCESS_TOKEN_LS)),
  isShowedInvalidParameters: false,
  isShowedButtonLoading: false,
  changeInvalidParameter: () => set({ isShowedInvalidParameters: false }),
  login: async (values) => {
    try {
      set({ isShowedButtonLoading: true });

      const user = await adminLoginController.login({
        userName: values.name,
        password: values.password,
      });

      localStorage.setItem(ACCESS_TOKEN_LS, user.token);
      apiService.setAccessToken(user.token);

      set({ user });
    } catch (error) {
      if (error.status === StatusCodes.INVALID_PARAMETERS)
        set({ isShowedInvalidParameters: true });
    } finally {
      set({ isShowedButtonLoading: false });
    }
  },
  set,
}));
