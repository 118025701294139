import React, { useEffect } from "react";
import { useQuestionStore } from "../../store";
import CustomPopup from "../shared/CustomPopup";

const QuestionsDetails = () => {
  const {
    selectedQuestionId,
    questionInfoIndividual,
    setSelectedQuestionId,

    questionStatistics,
  } = useQuestionStore();

  useEffect(() => {
    questionInfoIndividual(selectedQuestionId);
  }, [selectedQuestionId]);

  return (
    <CustomPopup
      title="Պատասխանների արդյունքներ"
      isShowed={Boolean(selectedQuestionId)}
      onClose={() => setSelectedQuestionId(null)}
    >
      <div className="table-responsive">
        <table border="1" className="table table_modal">
          <thead>
            <tr>
              <th>Տարբերակ</th>
              <th>Քվեարկություն</th>
              <th>Միջին %</th>
            </tr>
          </thead>
          <tbody>
            {questionStatistics ? (
              questionStatistics.map((question, idx) => {
                return (
                  <tr key={idx}>
                    <td>{question.answer}</td>
                    <td>{question.selectedCount}</td>
                    <td>{question.avarage}%</td>
                  </tr>
                );
              })
            ) : (
              <div className="spinner-border" role="status"></div>
            )}
          </tbody>
        </table>
      </div>
    </CustomPopup>
  );
};

export default QuestionsDetails;
