import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/basic-ui', state: 'basicUiMenuOpen' },
      { path: '/advanced-ui', state: 'advancedUiMenuOpen' },
      { path: '/form-elements', state: 'formElementsMenuOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/maps', state: 'mapsMenuOpen' },
      { path: '/editors', state: 'editorsMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' },
      { path: '/general-pages', state: 'generalPagesMenuOpen' },
      { path: '/ecommerce', state: 'ecommercePagesMenuOpen' },
      { path: '/community', state: 'communityPages' }
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  render() {
    return (
      <nav
        className='sidebar sidebar-offcanvas'
        id='sidebar'
        style={{ paddingTop: '30px' }}
      >
        <ul className='nav' style={{ position: 'fixed' }}>
          <li
            className={
              this.isPathActive('/questions') ? 'nav-item active' : 'nav-item'
            }
          >
            <Link className='nav-link' to='/questions'>
              <i className='fas fa-clipboard-list menu-icon'></i>
              <span className='menu-title'>Հարցեր</span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive('/users') ? 'nav-item active' : 'nav-item'
            }
          >
            <Link className='nav-link' to='/users'>
              <i className='fas fa-users menu-icon'></i>
              <span className='menu-title'>Օգտատերեր</span>
            </Link>
          </li>

          <li
            className={
              this.isPathActive('/community') ? 'nav-item active' : 'nav-item'
            }
          >
            <Link className='nav-link' to='/community'>
              <i class='fas fa-house-user menu-icon'></i>
              <span className='menu-title'>Համայնքներ</span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive('/certificate') ? 'nav-item active' : 'nav-item'
            }
          >
            <Link className='nav-link' to='/certificate'>
              <i className='fas fa-graduation-cap menu-icon'></i>
              <span className='menu-title'>Հավաստագիր</span>
            </Link>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  // componentDidMount() {
  //   this.onRouteChanged();
  //   // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
  //   const body = document.querySelector("body");
  //   document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
  //     el.addEventListener("mouseover", function () {
  //       if (body.classList.contains("sidebar-icon-only")) {
  //         el.classList.add("hover-open");
  //       }
  //     });
  //     el.addEventListener("mouseout", function () {
  //       if (body.classList.contains("sidebar-icon-only")) {
  //         el.classList.remove("hover-open");
  //       }
  //     });
  //   });
  // }
}

export default withRouter(Sidebar);
