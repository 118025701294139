import * as Yup from "yup";

const resetAdminValidation = Yup.object().shape({
  oldPassword: Yup.string()
    .required("Պարտադիր դաշտ")
    .min(
      6,
      "Գաղտնաբառը պետք է պարունակի լատինատառեր ու թվեր, նվազագույնը 6 նիշ, առավելագույնը 20 նիշ"
    )
    .max(
      20,
      "Գաղտնաբառը պետք է պարունակի լատինատառեր ու թվեր, նվազագույնը 6 նիշ, առավելագույնը 20 նիշ"
    )
    .label("Գաղտնաբառ")
    .matches(
      /(?:\d+[a-z]|[a-z]+\d)[a-z\d]*/,
      "Գաղտնաբառը պետք է պարունակի լատինատառեր ու թվեր, նվազագույնը 6 նիշ, առավելագույնը 20 նիշ"
    ),
  newPassword: Yup.string()
    .required("Պարտադիր դաշտ")
    .min(
      6,
      "Գաղտնաբառը պետք է պարունակի լատինատառեր ու թվեր, նվազագույնը 6 նիշ, առավելագույնը 20 նիշ"
    )
    .max(
      20,
      "Գաղտնաբառը պետք է պարունակի լատինատառեր ու թվեր, նվազագույնը 6 նիշ, առավելագույնը 20 նիշ"
    )
    .label("Գաղտնաբառ")
    .matches(
      /(?:\d+[a-z]|[a-z]+\d)[a-z\d]*/,
      "Գաղտնաբառը պետք է պարունակի լատինատառեր ու թվեր, նվազագույնը 6 նիշ, առավելագույնը 20 նիշ"
    ),
  repeatNewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Գաղտնաբառերը պետք է համընկնեն")
    .required("Պարտադիր դաշտ")
    .min(
      6,
      "Գաղտնաբառը պետք է պարունակի լատինատառեր ու թվեր, նվազագույնը 6 նիշ, առավելագույնը 20 նիշ"
    )
    .max(
      20,
      "Գաղտնաբառը պետք է պարունակի լատինատառեր ու թվեր, նվազագույնը 6 նիշ, առավելագույնը 20 նիշ"
    )
    .label("Գաղտնաբառ")
    .matches(
      /(?:\d+[a-z]|[a-z]+\d)[a-z\d]*/,
      "Գաղտնաբառը պետք է պարունակի լատինատառեր ու թվեր, նվազագույնը 6 նիշ, առավելագույնը 20 նիշ"
    ),
});

export default resetAdminValidation;
