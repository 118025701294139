import apiService from "../api";

class AdminResetController {
  async reset(userData) {
    try {
      const { data: userInfo } = await apiService.post(
        "/Auth/ResetPassword",
        userData
      );

      return userInfo.data;
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }
}

const adminResetController = new AdminResetController();

export default adminResetController;
