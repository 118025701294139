import cogoToast from 'cogo-toast';
import create from 'zustand';
import {
  communityAddController,
  communityDeleteController,
  communityGetController,
  communityIndividualGetController,
  communityUpdateController
} from '../services/controllers';
const toastOptions = {
  hideAfter: 5,
  position: 'top-right',
  heading: ''
};
export const useCommunityStore = create((set, get) => ({
  isShowedInvalidParameters: false,
  isShowedButtonLoading: false,

  selectedCommunityId: null,

  setSelectedCommunityId: (selectedCommunityId) => set({ selectedCommunityId }),

  changeInvalidParameter: () => set({ isShowedInvalidParameters: false }),
  removeEditedCommunity: () => set({ communityIndividual: null }),

  communities: [],
  states: [],

  communitiesGet: async (page, pageSize) => {
    try {
      const communities = await communityGetController.communityGet(
        page,
        pageSize
      );

      set({ communities: communities });
    } catch (error) {
      console.log(error);
    }
  },

  statesGet: async () => {
    try {
      const states = await communityGetController.statesGet();

      set({ states });
    } catch (error) {
      console.log(error);
    }
  },

  deletedCommunity: null,
  addDeletedCommunity: (deletedCommunity) => set({ deletedCommunity }),

  deleteCommunity: async (onPageSizeChange) => {
    const currentPage = get().communities?.data?.currentPage;
    const results = get().communities?.data?.results;

    try {
      const deletedCommunity = get().deletedCommunity;

      if (!deletedCommunity) return;

      await communityDeleteController.communityDelete(deletedCommunity);

      set({ deletedCommunity: null });
      get().communitiesGet(
        results?.length === 1 && currentPage > 1
          ? currentPage - 1
          : currentPage,
        10
      );
      if (onPageSizeChange)
        onPageSizeChange(
          results?.length === 1 && currentPage > 1
            ? currentPage - 1
            : currentPage
        );
      cogoToast.info('Դուք հաջողությամբ ջնջեցիք', toastOptions);
    } catch (error) {
      console.log(error);
      cogoToast.info(
        'Դուք չեք կարող ջնջել հարցը , քանի որ կա ակտիվ խաղ, խնդրում ենք փորձել քիչ անց',
        toastOptions
      );
    }
  },

  addCommunity: async (values) => {
    try {
      const validCommunities = values.communityTranslations.filter((c) =>
        c.community.trim()
      );

      if (!validCommunities.length) return;
      const editedCommunity = get().communityIndividual;

      const transformedCommunities = validCommunities?.map((c) => ({
        id: c.id,
        name: c.community,
        language: c.language.charAt(0).toUpperCase() + c.language.slice(1)
      }));
      set({ isShowedButtonLoading: true });
      if (editedCommunity) {
        await communityAddController.communityUpdate({
          communityTranslations: transformedCommunities,
          stateId: values.stateId
        });
        cogoToast.info('Դուք հաջողությամբ փոխեցիք', toastOptions);
      } else {
        await communityAddController.communityAdd({
          communityTranslations: transformedCommunities,
          stateId: values.stateId
        });
        cogoToast.info('Դուք հաջողությամբ ավելացրեցիք', toastOptions);
      }
      set({ isShowedButtonLoading: false });
      setTimeout(() => {
        window.location.replace('/community');
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  },

  communityIndividual: null,
  communityIndividualGet: async (communityId) => {
    try {
      const communities =
        await communityIndividualGetController.communityIndividualGet(
          communityId
        );
      set({
        communityIndividual: communities.map((c) => ({
          id: c.data._id,
          community: c.data.name,
          language: c.data.language
        }))
      });
    } catch (error) {
      console.log(error);
    }
  },

  updateCommunity: async (values) => {
    try {
      const communityUpdate = await communityUpdateController.communityUpdate(
        values
      );
      set({
        communityUpdate: communityUpdate.map((c) => ({
          id: c._id,
          name: c.name,
          language: c.language
        }))
      });
    } catch (error) {
      console.log(error);
    }
  }
}));
