import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GAME_TYPES } from '../../configs/app';
import { usePlayersStore } from '../../store/players';
import { CustomTabs } from '../shared';
import WrongAnswers from './WrongAnswers';

const WrongUsers = () => {
  const params = useParams();

  const [selectedGameType, setSelectedGameType] = useState(
    GAME_TYPES.ACTIVE_RESIDENT_OF_THE_COMMUNITY.id
  );

  const { getPlayerWrongAnswers, playerWrongAnswers } = usePlayersStore();

  const tabs = useMemo(() => {
    return [
      {
        key: GAME_TYPES.ACTIVE_RESIDENT_OF_THE_COMMUNITY.id,
        title: GAME_TYPES.ACTIVE_RESIDENT_OF_THE_COMMUNITY.name,
        content: <WrongAnswers />
      },
      {
        key: GAME_TYPES.CANDIDATE_FOR_THE_COUNCIL_OF_ELDERS.id,
        title: GAME_TYPES.CANDIDATE_FOR_THE_COUNCIL_OF_ELDERS.name,
        content: <WrongAnswers />
      }
    ];
  }, []);

  useEffect(() => {
    getPlayerWrongAnswers(params.playerId, selectedGameType);
  }, [selectedGameType]);

  return (
    <div className='wrong-answers'>
      <CustomTabs tabs={tabs} onChange={setSelectedGameType} />
    </div>
  );
};
export default WrongUsers;
