import React, { memo } from "react";
import SweetAlert from "sweetalert2-react";

const CustomPopup = ({
  isShowed,
  onConfirm = () => {},
  title,
  onClose = () => {},
  children,
}) => {
  return (
    <div className={isShowed ? "popup-wrapper" : "popup-wrapper-none"}>
      <div className="popup-box ">
        <div className="popup-box p-4 fonts">
          <div
            className="mb-5"
            style={{ fontSize: "25px", fontWeight: "bold" }}
          >
            {title}
            <i
              className="fas fa-times"
              onClick={onClose}
              style={{
                position: "absolute",
                right: "20px",
                cursor: "pointer",
              }}
            ></i>
          </div>
          {children || (
            <div className="d-flex justify-content-center">
              <button onClick={onConfirm} className="btn btn-success mr-2 ">
                Այո
              </button>
              <button
                onClick={onClose}
                type="button"
                className="btn btn-danger ml-2"
              >
                Ոչ
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(CustomPopup);
