import apiService from '../../api';

class CommunityIndividualGetController {
  async communityIndividualGet(communityId) {
    try {
      const communityResponse = await apiService.get(
        `/Community/${communityId}`
      );

      return [communityResponse.data];
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }
}

const communityIndividualGetController = new CommunityIndividualGetController();

export default communityIndividualGetController;
