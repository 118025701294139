import apiService from '../../api';

class QuestionDeleteController {
  async communityDelete(communityId) {
    try {
      await apiService.delete('/Community', `Id=${communityId}`);

      return Promise.resolve();
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }
}

const communityDeleteController = new QuestionDeleteController();

export default communityDeleteController;
