import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

const CustomPagination = ({ count = 1, value = 1, onChange = () => {} }) => {
  const [selected, setSelected] = useState(1);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  if (count < 2) return null;

  return (
    <>
      <ReactPaginate
        previousLabel={
          <>
            <span aria-hidden='true'>‹</span>
            <span class='sr-only'>Previous</span>
          </>
        }
        nextLabel={
          <>
            <span aria-hidden='true'>›</span>
            <span class='sr-only'>Next</span>
          </>
        }
        breakLabel={'...'}
        pageCount={count}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={(data) => {
          const page = data.selected + 1;

          if (!page) return;

          setSelected(page);
          onChange(page);
        }}
        nextClassName='page-item'
        nextLinkClassName='page-link'
        previousClassName='page-item'
        previousLinkClassName='page-link'
        pageClassName='page-item'
        pageLinkClassName='page-link'
        containerClassName='pagination pagination-dark d-flex justify-content-center mt-3'
        activeClassName={'active'}
      />
    </>
  );
};

export default CustomPagination;
