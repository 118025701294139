import apiService from '../../api';

class QuestionAddController {
  async communityAdd(questionData) {
    try {
      const communityResponse = await apiService.post(
        '/Community',
        questionData
      );
      return communityResponse.data;
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }

  async communityUpdate(communityData) {
    try {
      const communityResponse = await apiService.put(
        '/Community',
        communityData
      );

      return communityResponse.data;
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }
}

const communityAddController = new QuestionAddController();

export default communityAddController;
