import React, { useEffect, useMemo } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import amFlag from '../../assets/images/flags/flag-arm.svg';
import enFlag from '../../assets/images/flags/flag-en.svg';
import ruFlag from '../../assets/images/flags/flag-ru.svg';
import { useCommunityStore } from '../../store/community';
import communityAddValidation from '../../validations/communityAdd';
import { AppForm, ErrorMessage } from '../forms';
import { CustomButton } from '../shared';
import CustomTextArea from '../shared/CustomTextArea';
import { StatesSelect } from '../states-select';

const Communities = ({ isEdit = FontFaceSetLoadEvent }) => {
  const {
    isShowedButtonLoading,
    addCommunity,
    communityIndividual,
    communityIndividualGet,
    removeEditedCommunity
  } = useCommunityStore();

  const flagConfig = useMemo(
    () => ({
      am: {
        flag: amFlag,
        title: 'Armenia'
      },
      en: {
        flag: enFlag,
        title: 'English'
      },
      ru: {
        flag: ruFlag,
        title: 'Russian'
      }
    }),
    []
  );

  const params = useParams();

  useEffect(() => {
    communityIndividualGet(params.communityId);

    return () => {
      removeEditedCommunity();
    };
  }, []);

  if (isEdit && !communityIndividual) return null;

  return (
    <>
      <div>
        <div className='row communities_wrapper'>
          <div className='col-md-12 grid-margin stretch-card'>
            <div className='card border-0'>
              <div className='card-body card_tab'>
                <AppForm
                  initialValues={
                    communityIndividual
                      ? {
                          communityTranslations: [
                            ...communityIndividual,
                            ...['am', 'en', 'ru']
                              .filter(
                                (lang) =>
                                  !communityIndividual.find(
                                    (q) => q.language.toLowerCase() === lang
                                  )
                              )
                              ?.map((l) => ({
                                community: '',
                                language: l
                              }))
                          ],
                          stateId: null
                        }
                      : {
                          communityTranslations: [
                            {
                              community: '',
                              language: 'am'
                            },
                            {
                              community: '',
                              language: 'en'
                            },
                            {
                              community: '',
                              language: 'ru'
                            }
                          ],
                          stateId: null
                        }
                  }
                  onSubmit={addCommunity}
                  validationSchema={communityAddValidation}
                >
                  {(form) => {
                    const isNotFilledAnyCommunity =
                      form.isValid &&
                      form.submitCount > 0 &&
                      form.values.communityTranslations.every(
                        (q) => !q.community
                      );

                    return (
                      <>
                        <Tabs>
                          {form.values.communityTranslations.map(
                            (community, index) =>
                              !isEdit ? (
                                <Tab
                                  key={community.language}
                                  eventKey={community.language}
                                  title={
                                    <img
                                      src={
                                        flagConfig[
                                          community.language.toLowerCase()
                                        ]?.flag
                                      }
                                      alt={
                                        flagConfig[
                                          community.language.toLowerCase()
                                        ]?.title
                                      }
                                      className='w-100'
                                    />
                                  }
                                  className='test-tab'
                                >
                                  <div className='card card__tab'>
                                    <div className='card-body'>
                                      <div className='forms-sample'>
                                        <CustomTextArea
                                          maxLength={100}
                                          name={`communityTranslations[${index}].community`}
                                          htmlFor='community'
                                          title='Համայնք'
                                          className='form-control'
                                          rows='1'
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Tab>
                              ) : (
                                <Tab
                                  key={community.language}
                                  eventKey={community.language}
                                  className='test-tab'
                                >
                                  <div className='card card__tab'>
                                    <div className='card-body'>
                                      <div className='forms-sample'>
                                        <CustomTextArea
                                          maxLength={100}
                                          name={`communityTranslations[${index}].community`}
                                          htmlFor='community'
                                          title='Համայնք'
                                          className='form-control'
                                          rows='1'
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Tab>
                              )
                          )}
                        </Tabs>

                        {!isEdit && <StatesSelect />}

                        <div className='mt-5 text-center add-qustion-button'>
                          <CustomButton
                            buttonTitle={isEdit ? 'Փոփոխել' : 'Ավելացնել'}
                            className='btn btn-dark'
                            buttonIcon={
                              isShowedButtonLoading
                                ? 'spinner-border'
                                : `${
                                    isEdit ? 'fas fa-check' : 'fas fa-plus'
                                  } mr-2`
                            }
                            type='submit'
                          />

                          {isNotFilledAnyCommunity ? (
                            <ErrorMessage>Լրացրեք բոլոր դաշտերը</ErrorMessage>
                          ) : null}
                        </div>
                      </>
                    );
                  }}
                </AppForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Communities;
