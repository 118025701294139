import * as Yup from 'yup';

const communityAddValidation = Yup.object().shape({
  communityTranslations: Yup.array().of(
    Yup.object({
      community: Yup.string().max(
        300,
        'Հարցը պետք է պարունակի առավելագույնը 300 նիշ'
      )
    })
  ),
  stateId: Yup.mixed().required('Պարտադիր դաշտ')
});

export default communityAddValidation;
