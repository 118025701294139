import cogoToast from 'cogo-toast';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CheckIcon, CloseIcon } from '../../assets/images/svg';
import { usePlayersStore } from '../../store/players';
import CustomLoading from '../shared/CustomLoading';
import CustomPopup from '../shared/CustomPopup';
import CustomTable from '../shared/CustomTable';
import CustomPagination from '../shared/Pagination';
import handleDownload from './UsersDownload';

const toastOptions = {
  hideAfter: 5,
  position: 'top-right',
  heading: ''
};

const Users = () => {
  const history = useHistory();
  const [selected, setSelected] = useState(1);

  const {
    playersGet,
    playersExcelGet,
    players,
    deletedPlayer,
    addDeletedPlayer,
    deletePlayer
  } = usePlayersStore();

  const pageCount = players?.result?.pageCount;

  const { result } = players;
  useEffect(() => {
    playersGet(selected, 10);
  }, [playersGet, selected, deletedPlayer]);

  useEffect(() => {
    playersExcelGet(selected, 10);
  }, [playersExcelGet, selected, deletedPlayer]);

  return (
    <>
      <div className='users_wrapper'>
        <div
          className='users__heading'
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '20px'
            }}
          >
            <h4 className='font-weight-bold'>
              Ավագանու թեկնածու- {players.countOfCandidateForTheCouncilOfElders}
            </h4>
            <h4 className='font-weight-bold'>
              Համայնքի ակտիվ բնակիչ-
              {players.countOfActiveResidentOfTheCommunity}
            </h4>
          </div>
          <button
            className='btn btn-primary'
            onClick={() =>
              handleDownload(
                '/Playersreport.xlsx',
                'Playersreport.xlsx',
                `${result.currentPage}/${result.pageSize}`
              )
            }
          >
            <i className='fas fa-file-excel	'></i>
          </button>
        </div>

        {players?.result ? (
          <CustomTable
            isShownViewIcon={false}
            tableHeaders={[
              'Անուն',
              'Ազգանուն',
              'Հայրանուն',
              'Էլեկտրոնային հասցե',
              'Հեռախոս',
              'Համայնք',
              'Մակարդակ',
              'Բաժին',
              'Խաղացած խաղեր',
              'ժամանակ',
              'ճիշտ պատասխաններ',
              'Սխալ պատասխաններ',
              'Գործողություն'
            ]}
            tableColumns={players?.result?.results}
            renderColumns={(column) => (
              <>
                <td>
                  <div className='d-flex justify-content-center'>
                    <p className='number'>{column.firstName}</p>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <p className='number'>{column.lastName}</p>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <p className='number'>{column.surName}</p>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <p className='number'>{column.email}</p>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <p className='number'>{column.phone}</p>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <p className='number'>{column.community}</p>
                  </div>
                </td>
                <td>
                  <p style={{ paddingLeft: '5px', margin: 'auto' }}>
                    {column.level === 'ActiveResidentOfTheCommunity'
                      ? 'Active Resident Of The Community'
                      : 'Candidate For The Council Of Elders'}
                  </p>
                </td>
                <td>
                  <div className='d-flex justify-content-center align-items-center'>
                    {column.score >= 5 ? <CheckIcon /> : <CloseIcon />}

                    {column.section && (
                      <p style={{ paddingLeft: '5px', margin: 'auto' }}>
                        {
                          {
                            CommunityFiveYearPlan: 'Community Five Year Plan',
                            CommunityAnnualWorkPlan:
                              'Community Annual Work Plan',
                            CommunityBudget: 'Community Budget',
                            AnnualWorkPlanOfCommunity:
                              'Annual Work Plan Of Community'
                          }[column.section]
                        }
                      </p>
                    )}
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <p className='number'>{column.gamesCount}</p>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <p className='number'>
                      {column.gameTime === null ? '_' : column.gameTime}
                    </p>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <p className='number'>{column.score}</p>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <p className='number'>{column.wrongAnswersCount}</p>
                  </div>
                </td>
              </>
            )}
            onDeleteClick={(player) => addDeletedPlayer(player._id)}
            onEditClick={(playerId) => {
              playerId.wrongAnswersCount === 0
                ? cogoToast.info(
                    'Տվյալ օգտատերը սխալ պատասխաններ չունի',
                    toastOptions
                  )
                : history.push(`/wrong-answers/${playerId._id}`);
            }}
          />
        ) : (
          <CustomLoading />
        )}

        <CustomPopup
          title='Ջնջել՞'
          isShowed={Boolean(deletedPlayer)}
          onConfirm={() =>
            deletePlayer((currentPage) => setSelected(currentPage))
          }
          onClose={() => addDeletedPlayer(null)}
        />
      </div>
      {pageCount > 1 && (
        <CustomPagination
          count={pageCount}
          current={selected}
          onChange={(value) => setSelected(value)}
        />
      )}
      {/* <SweetAlert
        show={isShowedDeletedModal}
        title="Ջնջել"
        showCancelButton
        confirmButtonText="Այո"
        cancelButtonText="Ոչ"
        onConfirm={() => setShowedDeletedModal(false)}
        customClass="test123123123"
      /> */}
    </>
  );
};

export default Users;
