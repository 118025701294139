import apiService from '../../api';

class PlayerController {
  async playerDelete(playerID) {
    try {
      await apiService.get(`/Player/DeletePlayer?playerId=${playerID}`);

      return Promise.resolve();
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }

  async playersGet(pagination) {
    try {
      const playersResponse = await apiService.post(
        '/Player/GetPlayers',
        pagination
      );

      return {
        ...playersResponse.data.data,
        result: playersResponse.data.data.result
      };
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }

  async playersExcelGet(page, pageSize) {
    try {
      await apiService.get(`/Player/${page}/${pageSize}`);

      return Promise.resolve();
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }

  async getPlayerWrongAnswers(playerId, level) {
    try {
      const playersResponse = await apiService.post('/Player/GetWrongAnswers', {
        playerId,
        level
      });

      return playersResponse.data.data;
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }
}

const playerController = new PlayerController();

export default playerController;
