import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, Route, Switch } from 'react-router-dom';
import logo from '../../assets/images/svg/logowhite.svg';
import { ACCESS_TOKEN_LS } from '../../configs/storage';
import user from '../../assets/images/user.svg';

class Navbar extends Component {
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  toggleRightSidebar() {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }
  render() {
    return (
      <nav className='navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row'>
        <div
          className='text-center navbar-brand-wrapper d-flex align-items-center justify-content-center'
          style={{ paddingTop: '30px' }}
        >
          <Link className='navbar-brand brand-logo mr-5' to='/questions'>
            <img src={logo} className='mr-2' alt='logo' />
          </Link>
          <Link className='navbar-brand brand-logo-mini' to='/questions'>
            <img src={logo} className='mr-2' alt='logo' />
          </Link>
        </div>
        <div className='navbar-menu-wrapper d-flex align-items-stretch justify-content-end'>
          <button
            className='navbar-toggler navbar-toggler align-self-center'
            type='button'
            onClick={() => document.body.classList.toggle('sidebar-icon-only')}
          >
            <span className='ti-layout-grid2'></span>
          </button>
          <ul className='navbar-nav mr-lg-2'>
            <li className='nav-item nav-search d-none d-md-block'>
              <div className='input-group'>
                <h3 className='text-dark text-decoration-none'>
                  <Switch>
                    <Route
                      path='/questions'
                      exact
                      render={() => <div className='title-styles'>Հարցեր</div>}
                    />

                    <Route
                      path='/wrong-answers/:playerId'
                      exact
                      render={() => (
                        <div className='title-styles'>Սխալ պատասխաններ</div>
                      )}
                    />
                    <Route
                      path='/questions/add'
                      exact
                      render={() => <div className='title-styles'>Հարցեր</div>}
                    />
                    <Route
                      path='/questions/add'
                      exact
                      render={() => <div className='title-styles'>Հարցեր</div>}
                    />
                    <Route
                      path='/users'
                      exact
                      render={() => (
                        <div className='title-styles'>Օգտատերեր</div>
                      )}
                    />
                    <Route
                      path='/certificate'
                      exact
                      render={() => (
                        <div className='title-styles'>Հավաստագիր</div>
                      )}
                    />

                    <Route
                      path='/wrong-answers'
                      exact
                      render={() => (
                        <div className='title-styles'>Սխալ պատասխաներ</div>
                      )}
                    />
                    <Route
                      path='/community'
                      exact
                      render={() => (
                        <div className='title-styles'>Համայնքներ</div>
                      )}
                    />
                  </Switch>
                </h3>
              </div>
            </li>
          </ul>
          <ul className='navbar-nav navbar-nav-right'>
            <li className='nav-item nav-profile'>
              <Dropdown alignRight>
                <Dropdown.Toggle className='nav-link count-indicator'>
                  <img src={user} alt='profile' />
                </Dropdown.Toggle>
                <Dropdown.Menu className='preview-list navbar-dropdown'>
                  <Dropdown.Item
                    className='dropdown-item preview-item'
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <div className='d-flex align-items-center'>
                      <i className='ti-settings text-primary'></i>
                      <Link to='/reset-password' style={{ color: '#000' }}>
                        <span className='pl-2'>Փոխել գաղտնաբառը</span>
                      </Link>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className='dropdown-item preview-item'
                    onClick={(evt) => {
                      evt.preventDefault();
                      localStorage.removeItem(ACCESS_TOKEN_LS);
                      window.location.reload();
                    }}
                  >
                    <div className='d-flex align-items-center'>
                      <i className='ti-power-off text-primary'></i>
                      <span className='pl-2'>Ելք</span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
          <button
            className='navbar-toggler navbar-toggler-right d-lg-none align-self-center'
            type='button'
            onClick={this.toggleOffcanvas}
          >
            <span className='ti-layout-grid2'></span>
          </button>
        </div>
      </nav>
    );
  }
}

export default Navbar;
