import { ErrorMessage, FastField } from 'formik';
import React, { memo } from 'react';

import { default as CustomErrorMessage } from '../forms/ErrorMessage';

const AppFormField = ({
  name,
  wrapperClass,
  htmlForm,
  title,
  className,
  id,
  options = [],
  handleChange
}) => {
  return (
    <>
      <FastField name={name}>
        {({ field, _, meta }) => {
          return (
            <>
              <div>
                <div className={wrapperClass}>
                  <label htmlFor={htmlForm}>{title}</label>
                  <select className={className} id={id} name={name} {...field}>
                    <option value='' className='d-none'>
                      ընտրել
                    </option>
                    {options.map((option, idx) => (
                      <option
                        key={idx}
                        value={
                          typeof option === 'string' ? idx + 1 : option.value
                        }
                      >
                        {typeof option === 'string' ? option : option.label}
                      </option>
                    ))}
                    {/* <option value="1">1</option>
                    <option vslue="2">2</option> */}
                  </select>
                </div>

                <ErrorMessage name={name} component={CustomErrorMessage} />
              </div>
            </>
          );
        }}
      </FastField>
    </>
  );
};

export default memo(AppFormField);
