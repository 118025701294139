import cogoToast from 'cogo-toast';
import create from 'zustand';
import { certificationController } from '../services/controllers';

export const useCertificationStore = create((set, get) => ({
  certification: [], // players: [],
  isShowedButtonLoading: false,
  certificationGet: async () => {
    try {
      const certification = await certificationController.certificationGet();

      set({ certification });
    } catch (error) {
      console.log(error);
    }
  },

  certificationEdit: async (englishText, armenianText) => {
    try {
      await certificationController.certificationEdit(
        englishText,
        armenianText
      );

      cogoToast.success('Հաջողությամբ փոփոխված է!', {
        position: 'top-right'
      });
    } catch (error) {
      console.log(error);
    }
  },

  set
}));
