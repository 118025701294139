import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { routeUrls } from '../../configs/routes';
import { useQuestionStore } from '../../store/questions';
import { CustomButton } from '../shared';
import CustomPopup from '../shared/CustomPopup';
import CustomTable from '../shared/CustomTable';
import CustomPagination from '../shared/Pagination';
import QuestionsDetails from './QuestionsDetails';

const QuestionsList = () => {
  const [selected, setSelected] = useState(1);

  const history = useHistory();

  const {
    addDeletedQuestion,
    deletedQuestion,
    questionsGet,
    questions,
    deleteQuestion,
    setSelectedQuestionId
  } = useQuestionStore();

  const onAddQuestionClick = useCallback(
    () => history.push(routeUrls.ADD_QUESTION),
    [history]
  );

  const pageCount = questions?.pageCount;

  useEffect(() => {
    questionsGet(selected, 10);
  }, [questionsGet, selected]);

  return (
    <>
      <div className='questions_wrapper'>
        <div className='text-center text-md-right'>
          <CustomButton
            handleButtonClick={onAddQuestionClick}
            buttonTitle='Ավելացնել հարց'
            className='btn btn-dark'
            buttonIcon='fas fa-plus mr-2'
          />
        </div>
        <CustomTable
          isShowedEditIcon
          isShownViewIcon
          tableHeaders={['Հարց', 'Մակարդակ', 'Բաժին', 'Գործողություն']}
          tableColumns={questions?.results}
          renderColumns={(column) => (
            <>
              <td>
                <div className='columnCell'>{column.question}</div>
              </td>
              <td>
                {column.level === 'ActiveResidentOfTheCommunity'
                  ? 'Համայնքի ակտիվ բնակիչ'
                  : column.level === 'CandidateForTheCouncilOfElders'
                  ? 'Ավագանու թեկնածու'
                  : '-'}
              </td>
              <td>
                {column.section === 'CommunityFiveYearPlan'
                  ? 'Community Five Year Plan'
                  : 'CommunityAnnualWorkPlan'
                  ? 'Community Annual Work Plan'
                  : 'Community Budget'}
              </td>
            </>
          )}
          onDeleteClick={(question) => addDeletedQuestion(question._id)}
          onDetailsClick={(question) => {
            history.push(`/questions/edit/${question._id}`);
          }}
          onEditClick={(question) => {
            setSelectedQuestionId(question._id);
          }}
        />
        {pageCount > 1 && (
          <CustomPagination
            count={pageCount}
            current={selected}
            onChange={(value) => setSelected(value)}
          />
        )}
        <QuestionsDetails />
        <CustomPopup
          title='Ջնջել՞'
          isShowed={Boolean(deletedQuestion)}
          onConfirm={() =>
            deleteQuestion((currentPage) => setSelected(currentPage))
          }
          onClose={() => addDeletedQuestion(null)}
        />
      </div>
    </>
  );
};

export default QuestionsList;
